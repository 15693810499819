import React from "react"
import { Link } from "gatsby"
import "./footer.css"
import { Col } from "react-bootstrap"

import LogoFooter from "../../images/logo-v2.png"

const footer = () => (
	<div>
		<footer>
			<div className="footer-container">
				<div className="container">
					<div className="row my-5">
						<div className="col-lg-3">
							<div className="p-3 subfooter-info">
								<img
									className="w-100"
									src={LogoFooter}
									alt="Company Footer Logo"
								/>
								<p id="gray" className="mb-0">
									Temporibus autem quibusdam et aut officiis debitis aut rerum
									necessitatibus saepe eveniet ut et voluptates repudiandae sint
									et molestiae non recusandae.
								</p>
							</div>
						</div>
						<div className="col-lg-2">
							<h3 className="subfooter-header">Links</h3>
							<hr className="mb-4" data-aos="fade" />
							<div className="row footer-links">
								<div className="col">
									<ul className="list-unstyled ml-0">
										<li>
											<Link to="/">Home</Link>
										</li>
										<li>
											<Link to="/">About Us</Link>
										</li>
										<li>
											<Link to="/">Products</Link>
										</li>
									</ul>
								</div>
								<div className="col">
									<ul className="list-unstyled ml-0">
										<li>
											<Link to="/">Service</Link>
										</li>
										<li>
											<Link to="/">Contact Us</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<h3 className="subfooter-header">LEGAL</h3>
							<hr className="mb-4" data-aos="fade" />
							<div className="row footer-links">
								<div className="col">
									<ul className="list-unstyled ml-0">
										<li>
											<Link to="/">Terms & Conditions</Link>
										</li>
										<li>
											<Link to="/">Privacy Statement</Link>
										</li>
									</ul>
								</div>
								<div className="col">
									<ul className="list-unstyled ml-0">
										<li>
											<Link to="/">Protection Policy</Link>
										</li>
										<li>
											<Link to="/">Shipping & Returns</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3 subfooter-header">
							<h3 className="m-0 p-0">
								FOLLOW CANNABIS <span style={{ color: `#48af5d` }}>SOUTH</span>
							</h3>
							<hr className="mb-4" data-aos="fade" />
							<div className="row footer-icons">
								<div className="col-lg-4">
									<ul className="list-unstyled ml-0 d-inline-flex">
										<li className="mr-4">
											<a href="/">
												<i className="fa fa-facebook-f" />
											</a>
										</li>
										<li className="mr-4">
											<a href="/">
												<i className="fa fa-twitter" />
											</a>
										</li>
										<li className="mr-4">
											<a href="/">
												<i className="fa fa-linkedin" />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<div className="footer-dark text-white-50 copyright">
			<div className="container">
				<div className="row mx-5">
					<Col md={6} sm={12}>
						<p>
							<sup>©</sup> {new Date().getFullYear()} Cannabis South, All Rights
							Reserved
						</p>
					</Col>
					<Col md={6} sm={12}>
						<p className="float-right">
							Designed & Powered by{" "}
							<a
								href="http://webriq.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								WebriQ
							</a>
						</p>
					</Col>
				</div>
			</div>
		</div>
	</div>
)

export default footer
