import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"

import NavLogo from "../../images/logo-v2.png"

export default () => (
	<div className="navigation text-center">
		<Link className="sidebar-logo" to="/">
			<img
				className="img-fluid"
				loading="lazy"
				alt="Cannabis South Logo"
				src={NavLogo}
			/>
		</Link>
		<Nav className="flex-column">
			<Nav.Item>
				<Link className="nav-fonts" to="/">
					Home
				</Link>
			</Nav.Item>
			<Nav.Item>
				<Link className="nav-fonts" to="/#about-cannabis">
					About Us
				</Link>
			</Nav.Item>
			<Nav.Item>
				<Link className="nav-fonts" to="/#our-products">
					Products
				</Link>
			</Nav.Item>
			<Nav.Item>
				<Link className="nav-fonts" to="/#our-services">
					Services
				</Link>
			</Nav.Item>
			<Nav.Item>
				<Link className="nav-fonts" to="/#contact-us">
					Contact Us
				</Link>
			</Nav.Item>
		</Nav>
	</div>
)
