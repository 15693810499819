import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import Sticky from "react-sticky-el"

import SideBarMenu from "../sidebar/sidebar"
import "react-lazy-load-image-component/src/effects/blur.css"

// import "./header.css"
import "./header.module.css"
import "./header.css"

import NavLogo from "../../images/logo-v2.png"

const Header = () => (
	<header>
		<Sticky
			id="sticky-wrapper"
			className="sticky-wrapper"
			stickyClassName="is-sticky"
			stickyStyle={{ transform: "unset", zIndex: "999" }}
		>
			<Nav
				className="navbar navbar-light navbar-expand-md navigation-clean"
				style={{ zIndex: "1" }}
			>
				<div className="container">
					<div className="row w-100">
						<div className="col-9 col-lg-4 col-md-5">
							<Link className="navbar-brand" to="/">
								<img
									className="img-fluid mb-0"
									loading="lazy"
									alt="Cannabis South Logo"
									src={NavLogo}
								/>
							</Link>
						</div>
						<div className="col-3 col-lg-8 col-md-7">
							<SideBarMenu />
						</div>
					</div>
				</div>
			</Nav>
		</Sticky>
	</header>
)

export default Header
